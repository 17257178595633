import { Box, Heading, Flex } from "@chakra-ui/react";
import BenefitSingle from "./BenefitSingle";

const BenefitSection = ({ data }) => {
  const { title, benefits } = data;
  return (
    <Box
      mb="8"
      pb="8"
      borderBottom="1px"
      borderBottomColor="gray.300"
      _last={{
        borderBottom: "none",
        pb: "0",
        mb: "0",
      }}
    >
      <Heading
        px="4"
        as="h3"
        fontSize={"lg"}
        mb="0"
        color={"content.500"}
        fontFamily="body"
      >
        {title}
      </Heading>

      {benefits && (
        <Flex wrap={"wrap"}>
          {benefits.map(({ title, logo, excerpt }, key) => (
            <Flex w={["50%", "50%", "25%"]} align={"center"} p={4} key={key}>
              <BenefitSingle title={title} logo={logo} excerpt={excerpt} />
            </Flex>
          ))}
        </Flex>
      )}
    </Box>
  );
};

export default BenefitSection;
